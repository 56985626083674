import { Route, Routes } from "react-router-dom";
import "../App.css";
import Portfolio from "@src/pages/portfolio";

const STYLE = {
  width: "100%",
  height: "100%",
  backgroundColor: "var(--gray_900)",
};

const ROUTE = [
  {
    path: "/",
    element: <Portfolio />,
  },
  // { path: "/", element: <App /> },
  // { path: "/main", element: <Main /> },
  // {
  //   path: "/main/1",
  //   element: (
  //     <div style={STYLE}>
  //       <MainFirstSection />
  //     </div>
  //   ),
  // },
  // {
  //   path: "/main/2",
  //   element: (
  //     <div style={STYLE}>
  //       <MainSecondSection />
  //     </div>
  //   ),
  // },
  // {
  //   path: "/main/3",
  //   element: (
  //     <div style={STYLE}>
  //       <MainThirdSection />
  //     </div>
  //   ),
  // },
  // {
  //   path: "/main/4",
  //   element: (
  //     <div style={STYLE}>
  //       <MainFourthSection list={LIST} />
  //     </div>
  //   ),
  // },
  // {
  //   path: "/feature",
  //   element: <Feature />,
  // },
  // {
  //   path: "/feature/1",
  //   element: (
  //     <div style={STYLE}>
  //       <FeatureFirstSection />
  //     </div>
  //   ),
  // },
  // {
  //   path: "/feature/2",
  //   element: (
  //     <div style={STYLE}>
  //       <FeatureSecondSection />
  //     </div>
  //   ),
  // },
  // {
  //   path: "/feature/3",
  //   element: (
  //     <div style={STYLE}>
  //       <FeatureThirdSection />
  //     </div>
  //   ),
  // },
  // {
  //   path: "/full/main",
  //   element: <FullPageMain />,
  // },

  // {
  //   path: "/full/feature",
  //   element: <FullPageFeature />,
  // },
  // {
  //   path: "/test",
  //   element: <Test />,
  // },
] as const;

const RouterSwitch = () => {
  return (
    <>
      {/* <Header /> */}
      <Routes>
        {ROUTE.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={element}
          />
        ))}
      </Routes>
    </>
  );
};

export default RouterSwitch;
