import { useRef, useEffect } from "react";
import styles from "./sectionProduct.module.scss";

interface IProps {
  name: string;
  description: string;
  keyPoints: string;
  labels: JSX.Element[];
  developmentScope: string;
  service: string;
  client: string;
  image: string;
  video: string;
  className?: string;
}

const Contents = ({
  name,
  description,
  keyPoints,
  labels,
  developmentScope,
  service,
  client,
  image,
  video,
  className = "",
}: IProps) => {
  const observer = useRef<IntersectionObserver>();
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleIntersection = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => {
    entries.forEach((entry) => {
      const { isIntersecting } = entry;
      videoRef.current?.[isIntersecting ? "play" : "pause"]();
    });
  };

  useEffect(() => {
    if (videoRef.current) {
      if (!observer.current) {
        observer.current = new IntersectionObserver(handleIntersection, {
          threshold: [0.3, 0.7],
        });
        videoRef.current && observer.current.observe(videoRef.current);
      }
    }
  }, [videoRef.current]);

  return (
    <>
      <div className={[styles.left, className].join(" ")}>
        <div
          className={styles.column}
          style={{ marginTop: "10px" }}
        >
          <span className={styles.name}>{name}</span>
          <span className={styles.description}>{description}</span>
        </div>
        <div className={styles.column}>
          <span className={styles.title}>주요사항</span>
          <span className={styles.text}>{keyPoints}</span>
        </div>
        <div className={styles.column}>
          <span className={styles.title}>적용기술</span>
          <div
            className={styles.row}
            style={{ gap: 8, flexWrap: "wrap" }}
          >
            {labels.map((Label, index) => (
              <span key={index}>{Label}</span>
            ))}
          </div>
        </div>
        <div className={styles.column}>
          <span className={styles.title}>개발범위</span>
          <span className={styles.text}>{developmentScope}</span>
        </div>
        <div
          className={styles.responsive_row}
          style={{ marginTop: "auto" }}
        >
          <div
            className={styles.column}
            style={{ flex: 1, justifyContent: "start" }}
          >
            <img
              className={styles.logo_image}
              alt={name}
              src={service}
            />
            <span className={styles.text_small}>서비스</span>
          </div>
          <div
            className={styles.column}
            style={{ flex: 1, justifyContent: "start" }}
          >
            <img
              className={styles.logo_image}
              alt={name}
              src={client}
            />
            <span className={styles.text_small}>클라이언트</span>
          </div>
        </div>
      </div>
      <div className={[styles.right, className].join(" ")}>
        <div className={styles.media_box}>
          <video
            ref={videoRef}
            src={video}
            playsInline
            muted
            loop
          />
          <img
            src={image}
            alt={name}
          />
        </div>
      </div>
    </>
  );
};

const SectionProduct = ({ className = "", ...props }: IProps) => {
  return (
    <>
      <div className={[styles.section_product, className].join(" ")}>
        <Contents {...props} />
      </div>
      <Contents
        {...props}
        className={[styles.hide, className].join(" ")}
      />
    </>
  );
};

export default SectionProduct;
