import styles from "./sectionFour.module.scss";
import Icon from "@src/components/common/Icon";

interface IProps {
  className?: string;
}

const SectionFour = ({ className = "" }: IProps) => {
  return (
    <div className={[styles.section_four, className].join(" ")}>
      <div className={styles.contents}>
        <Icon
          name="DoubleCycle"
          className={styles.background}
        />
        <Icon
          name="DoubleCycleVertical"
          className={[styles.background, styles.mobile].join(" ")}
        />
        <div className={styles.left}>
          <span className={styles.title}>약속</span>
          <span className={styles.text}>
            고객과 함께 <br />
            미래를 설계하며,
            <br />
            새로운 가능성을
            <br />
            열어갑니다.
          </span>
        </div>
        <div className={styles.right}>
          <span className={styles.title}>성장</span>
          <span className={styles.text}>
            고객과 공동의
            <br />
            목표를 달성하고,
            <br />
            성공을 이끌며 새로운
            <br />
            기회를 개척합니다.
            <br />
          </span>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
