import Icon from "@src/components/common/Icon";
import styles from "./sectionOne.module.scss";

interface IProps {
  className?: string;
}

const SectionOne = ({ className = "" }: IProps) => {
  return (
    <div className={[styles.section_one, className].join(" ")}>
      <Icon
        name="MainLogo"
        className={styles.main_logo}
      />
      <Icon
        name="Symbol"
        className={styles.symbol}
      />
    </div>
  );
};

export default SectionOne;
