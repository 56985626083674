import { CSSProperties } from "react";
import { ReactComponent as HeaderLogo } from "@src/assets/svg/header_logo.svg";
import { ReactComponent as MainLogo } from "@src/assets/svg/main_logo.svg";
import { ReactComponent as Symbol } from "@src/assets/svg/symbol.svg";
import { ReactComponent as Water } from "@src/assets/svg/water.svg";
import { ReactComponent as DoubleCycle } from "@src/assets/svg/double_cycle.svg";
import { ReactComponent as DoubleCycleVertical } from "@src/assets/svg/double_cycle_vertical.svg";
import { ReactComponent as CycleRightShadow } from "@src/assets/svg/cycle_right_shadow.svg";
import { ReactComponent as CycleLeftShadow } from "@src/assets/svg/cycle_left_shadow.svg";

const ICONS = {
  HeaderLogo,
  MainLogo,
  Symbol,
  Water,
  DoubleCycle,
  DoubleCycleVertical,
  CycleRightShadow,
  CycleLeftShadow,
} as const;

export interface IProps {
  name: keyof typeof ICONS;
  style?: CSSProperties;
  className?: string;
}

const Icon = ({ name, style, className = "" }: IProps) => {
  const Svg = ICONS[name];

  return (
    <Svg
      style={style}
      className={className}
    />
  );
};

export default Icon;
