import { CSSProperties } from "react";
import styles from "./iconLabel.module.scss";
import LogoALB from "@src/assets/icons/logo_alb.png";
import LogoCafe24 from "@src/assets/icons/logo_cafe24.png";
import LogoCloudfront from "@src/assets/icons/logo_cloudfront.png";
import LogoDjango from "@src/assets/icons/logo_django.png";
import LogoElectron from "@src/assets/icons/logo_electron.png";
import LogoKoreaCB from "@src/assets/icons/logo_korea_cb.png";
import LogoMysql from "@src/assets/icons/logo_mysql.png";
import LogoNestJS from "@src/assets/icons/logo_nestjs.png";
import LogoNext from "@src/assets/icons/logo_next.png";
import LogoPrisma from "@src/assets/icons/logo_prisma.png";
import LogoReact from "@src/assets/icons/logo_react.png";
import LogoRedis from "@src/assets/icons/logo_redis.png";
import LogoS3 from "@src/assets/icons/logo_s3.png";
import LogoTossPayments from "@src/assets/icons/logo_toss_payments.png";
import LogoTypeScript from "@src/assets/icons/logo_type_script.png";

const ICONS = {
  LogoALB,
  LogoCafe24,
  LogoCloudfront,
  LogoDjango,
  LogoElectron,
  LogoKoreaCB,
  LogoMysql,
  LogoNestJS,
  LogoNext,
  LogoPrisma,
  LogoReact,
  LogoRedis,
  LogoS3,
  LogoTossPayments,
  LogoTypeScript,
} as const;

interface IProps {
  name: keyof typeof ICONS;
  style?: CSSProperties;
  className?: string;
  text: string;
}

const IconLabel = ({ name, text, className = "" }: IProps) => {
  return (
    <div className={[styles.icon_label, className].join(" ")}>
      <img
        alt={name}
        src={ICONS[name]}
        className={styles.icon}
      />
      <span className={styles.text}>{text}</span>
    </div>
  );
};

export default IconLabel;
