import styles from "./sectionSix.module.scss";
import Icon from "@src/components/common/Icon";

interface IProps {
  className?: string;
}

const SectionSix = ({ className = "" }: IProps) => {
  return (
    <div className={[styles.section_six, className].join(" ")}>
      <div className={styles.left}>
        <div className={styles.box}>
          <Icon
            name="CycleLeftShadow"
            className={styles.background}
          />
          <span className={styles.text}>
            What <br />
            We Do
          </span>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.box}>
          <span className={styles.cycle} />
          <span className={styles.title}>Development</span>
          <span className={styles.text}>
            고객의 니즈를 이해하여 <br />
            다양한 개발 영역에서 <br />
            혁신적인 솔루션을 제공
          </span>
        </div>
        <div className={styles.box}>
          <span className={styles.cycle} />
          <span className={styles.title}>UI/UX Design</span>
          <span className={styles.text}>
            사용자의 요구사항을 이해하여 <br />
            직관적인 인터페이스와 <br />
            만족스러운 사용자 경험을 구축
          </span>
        </div>
        <div className={styles.box}>
          <span className={styles.cycle} />
          <span className={styles.title}>IT Consulting</span>
          <span className={styles.text}>
            성공적인 비즈니스를 위한 <br />
            IT 전략을 수립하고 기술적인 <br />
            문제에 대한 해결책 제시
          </span>
        </div>
        <div className={styles.box}>
          <span className={styles.cycle} />
          <span className={styles.title}>Branding</span>
          <span className={styles.text}>
            제품의 핵심 가치를 반영하는 <br />
            일관된 브랜드 경험을 위한 <br />
            아이덴티티를 설계
          </span>
        </div>
        <div className={styles.box}>
          <span className={styles.cycle} />
          <span className={styles.title}>Marketing</span>
          <span className={styles.text}>
            디지털 캠페인, 퍼포먼스 마케팅, <br />
            SEO 등 다양한 마케팅 방법론과 <br />
            지속적인 데이터 분석을 통해 <br />
            마케팅 최적화
          </span>
        </div>
        <div className={styles.box}>
          <span className={styles.cycle} />
          <span className={styles.title}>Content</span>
          <span className={styles.text}>
            그래픽 디자인, 카피라이팅, <br />
            2D/3D 모델링 및 영상제작 등 <br />
            고객이 필요로 하는 다양한 <br />
            콘텐츠 제작
          </span>
        </div>
      </div>
    </div>
  );
};

export default SectionSix;
