import { useEffect, useState } from "react";
import "@shinyongjun/react-fullpage/css";
import {
  FullpageContainer,
  FullpageSection,
} from "@shinyongjun/react-fullpage";

import Icon from "@src/components/common/Icon";
import IconLabel from "@src/components/common/IconLabel";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import SectionSix from "./SectionSix";
import SectionSeven from "./SectionSeven";
import SectionProduct from "./SectionProduct";
import SectionLast from "./SectionLast";

import ZeloImage from "@src/assets/images/product_zelo.png";
import TriveImage from "@src/assets/images/product_trive.png";
import Palette from "@src/assets/images/product_palette.png";

import TriveDemoOne from "@src/assets/videos/01_trive.mp4";
import TriveDemoTwo from "@src/assets/videos/02_trive.mp4";
import TriveDemoThree from "@src/assets/videos/03_trive.mp4";
import PaletteDemo from "@src/assets/videos/04_palette.mp4";
import ZeloDemo from "@src/assets/videos/05_zelo.mp4";

import LogoZelo from "@src/assets/images/logo_zelo.png";
import LogoOvermaps from "@src/assets/images/logo_overmaps.png";

import LogoTriveOld from "@src/assets/images/logo_trive_old.png";
import LogoThetTriveOld from "@src/assets/images/logo_the_trive_old.png";

import LogoTrive from "@src/assets/images/logo_trive.png";
import LogoThetTrive from "@src/assets/images/logo_the_trive.png";

import LogoPalette from "@src/assets/images/logo_palette.png";
import LogoPamsUnion from "@src/assets/images/logo_pams_union.png";
import styles from "./portfolio.module.scss";

const LAST_PAGE = 8;
const TOUCH_MOVE_POSITION = 100;

const PROJECTS: {
  name: string;
  description: string;
  keyPoints: string;
  developmentScope: string;
  labels: JSX.Element[];
  service: string;
  client: string;
  image: string;
  video: string;
}[] = [
  {
    name: "젤로",
    description: "다양한 여행, 취미, 육아 상품들을 테마화 한 단기 렌탈 플랫폼",
    keyPoints:
      "코리아 크레딧 뷰로를 통한 자동 신용평가 및 심사 / 자동화된 CRM으로 관리 포인트 최소화",
    labels: [
      <IconLabel
        className={styles.icon_label}
        name="LogoReact"
        text="React"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoNext"
        text="nextjs"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoTypeScript"
        text="Type Script"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoNestJS"
        text="Nestjs"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoPrisma"
        text="Prisma.io"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoMysql"
        text="Aurora Mysql"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoCloudfront"
        text="cloudfront"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoALB"
        text="ALB"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoS3"
        text="S3"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoTossPayments"
        text="Toss Payments"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoKoreaCB"
        text="Korea CB"
      />,
    ],
    developmentScope:
      "렌탈 웹사이트, 계약관리 시스템, 고객관리 시스템, AWS 인프라 구축",
    service: LogoZelo,
    client: LogoOvermaps,
    image: ZeloImage,
    video: ZeloDemo,
  },
  {
    name: "트라이브 v1",
    description: "온라인 B2C 중고차 판매",
    keyPoints:
      "중고차 매물 정보 확보와 가격정보 분석을 통한 합리적인 차량 구매 몰",
    developmentScope:
      "중고차 판매 모바일 앱, 차량 관리 시스템, 계약관리 시스템",
    labels: [
      <IconLabel
        className={styles.icon_label}
        name="LogoReact"
        text="React"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoElectron"
        text="electron"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoTypeScript"
        text="Type Script"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoDjango"
        text="django"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoMysql"
        text="Mysql"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoCloudfront"
        text="cloudfront"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoS3"
        text="S3"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoTossPayments"
        text="Toss Payments"
      />,
    ],
    service: LogoTriveOld,
    client: LogoThetTriveOld,
    image: TriveImage,
    video: TriveDemoOne,
  },
  {
    name: "트라이브 v2",
    description: "온라인 B2C 자동차 판매",
    keyPoints:
      "카드사 프로모션을 연동한 저금리 할부 시스템으로 소비자의 부담을 줄여줌",
    developmentScope:
      "중고차 판매 모바일 앱, 차량 관리 시스템, 계약관리 시스템",
    labels: [
      <IconLabel
        className={styles.icon_label}
        name="LogoReact"
        text="React"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoReact"
        text="React Native"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoTypeScript"
        text="Type Script"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoDjango"
        text="django"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoMysql"
        text="Mysql"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoCloudfront"
        text="cloudfront"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoS3"
        text="S3"
      />,
    ],
    service: LogoTriveOld,
    client: LogoThetTriveOld,
    image: TriveImage,
    video: TriveDemoTwo,
  },
  {
    name: "트라이브 latest",
    description: "프리미엄 자동차 구독 서비스",
    keyPoints: "최소 3개월 단기 렌탈 서비스로서 소비자의 부담을 줄여줌",
    developmentScope:
      "자동차 구독 앱, 차량 관리 시스템, 고객 관리 시스템, 구독 관리 시스템, 중고차 시세 분석 시스템, 신용 승인 자동화",
    labels: [
      <IconLabel
        className={styles.icon_label}
        name="LogoReact"
        text="React"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoNext"
        text="nextjs"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoReact"
        text="React Native"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoTypeScript"
        text="Type Script"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoNestJS"
        text="Nestjs"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoPrisma"
        text="Prisma.io"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoMysql"
        text="Aurora Mysql"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoCloudfront"
        text="cloudfront"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoRedis"
        text="redis"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoALB"
        text="ALB"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoS3"
        text="S3"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoTossPayments"
        text="Toss Payments"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoKoreaCB"
        text="Korea CB"
      />,
    ],
    service: LogoTrive,
    client: LogoThetTrive,
    image: TriveImage,
    video: TriveDemoThree,
  },
  {
    name: "팔레트",
    description: "중장기 가전 렌탈 서비스",
    keyPoints: "cafe24 플랫폼을 사용한 가전 렌탈 서비스",
    developmentScope:
      "cafe24 데이터 연동을 통한 가전 렌탈 웹사이트, 계약 관리 시스템, 비교 견적 시스템",
    labels: [
      <IconLabel
        className={styles.icon_label}
        name="LogoReact"
        text="React"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoNext"
        text="nextjs"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoTypeScript"
        text="Type Script"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoNestJS"
        text="Nestjs"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoPrisma"
        text="Prisma.io"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoMysql"
        text="Aurora Mysql"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoCloudfront"
        text="cloudfront"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoALB"
        text="ALB"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoS3"
        text="S3"
      />,
      <IconLabel
        className={styles.icon_label}
        name="LogoCafe24"
        text="cafe24"
      />,
    ],
    service: LogoPalette,
    client: LogoPamsUnion,
    image: Palette,
    video: PaletteDemo,
  },
];

const Portfolio = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(activeIndex);

  const handleClickLink = (movePage: number) => {
    setActiveIndex(movePage);
  };

  const handleKeyboardEvent = (event: KeyboardEvent) => {
    const key = event.key;

    switch (key) {
      case "ArrowRight":
      case "ArrowDown":
      case "PageDown":
        setActiveIndex((page) => {
          if (page >= LAST_PAGE + PROJECTS.length) return page;

          return page + 1;
        });
        break;
      case "ArrowLeft":
      case "ArrowUp":
      case "PageUp":
        setActiveIndex((page) => {
          if (page <= 0) return page;

          return page - 1;
        });
        break;
      case "Home":
        setActiveIndex(0);
        break;
      case "End":
        setActiveIndex(LAST_PAGE + PROJECTS.length - 1);
    }
  };

  useEffect(() => {
    setCurrentIndex(activeIndex);
  }, [activeIndex]);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyboardEvent);

    return () => {
      window.removeEventListener("keyup", handleKeyboardEvent);
    };
  }, []);

  return (
    <section className={[styles.portfolio, styles[activeIndex + 1]].join(" ")}>
      <div
        className={[
          styles.header,
          styles[[1, 6, 13].includes(activeIndex + 1) ? "" : "primary"],
        ].join(" ")}
      >
        <button
          className={styles.header_logo}
          onClick={() => setActiveIndex(0)}
        >
          <Icon name="HeaderLogo" />
        </button>
      </div>

      <div className={styles.body}>
        <FullpageContainer
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          <FullpageSection>
            <SectionOne
              className={activeIndex === 0 ? "active" : "deactivate"}
            />
          </FullpageSection>
          <FullpageSection>
            <SectionTwo
              className={activeIndex === 1 ? "active" : "deactivate"}
            />
          </FullpageSection>
          <FullpageSection>
            <SectionThree
              className={activeIndex === 2 ? "active" : "deactivate"}
            />
          </FullpageSection>
          <FullpageSection>
            <SectionFour
              className={activeIndex === 3 ? "active" : "deactivate"}
            />
          </FullpageSection>
          <FullpageSection>
            <SectionFive
              className={activeIndex === 4 ? "active" : "deactivate"}
            />
          </FullpageSection>
          <FullpageSection>
            <SectionSix
              className={activeIndex === 5 ? "active" : "deactivate"}
            />
          </FullpageSection>
          <FullpageSection>
            <SectionSeven
              className={activeIndex === 6 ? "active" : "deactivate"}
            />
          </FullpageSection>
          {PROJECTS.map((project, index) => (
            <FullpageSection>
              <SectionProduct
                className={
                  activeIndex > 6 &&
                  activeIndex < LAST_PAGE + PROJECTS.length - 1
                    ? "active"
                    : "deactivate"
                }
                key={`product-${index}`}
                {...project}
              />
            </FullpageSection>
          ))}
          <FullpageSection>
            <SectionLast
              className={
                activeIndex === LAST_PAGE + PROJECTS.length - 1
                  ? "active"
                  : "deactivate"
              }
            />
          </FullpageSection>
        </FullpageContainer>
      </div>

      {[2, 3].includes(activeIndex + 1) && (
        <Icon
          className={styles.background}
          name="Water"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "auto",
          }}
        />
      )}

      <div
        className={[
          styles.footer,
          [3, 4, 6, 7, 8, 9, 10, 11].includes(activeIndex)
            ? styles.primary
            : "",
        ].join(" ")}
      >
        <span className={styles.text}>{`${currentIndex + 1}/${
          LAST_PAGE + PROJECTS.length
        }`}</span>
        {[0, 12].includes(activeIndex) && (
          <span className={styles.text}>(주)코멘다 회사 소개서</span>
        )}
        {![0, 12].includes(activeIndex) && (
          <div className={[styles.guide].join(" ")}>
            <button
              className={[
                styles.button,
                [1, 2].includes(activeIndex) && styles.active,
              ].join(" ")}
              onClick={() => handleClickLink(1)}
            >
              <span>About</span>
              <span>About</span>
              <span>About</span>
            </button>
            <button
              className={[
                styles.button,
                activeIndex === 3 && styles.active,
              ].join(" ")}
              onClick={() => handleClickLink(3)}
            >
              <span>Core Values</span>
              <span>Core Values</span>
              <span>Core Values</span>
            </button>
            <button
              className={[
                styles.button,
                activeIndex === 4 && styles.active,
              ].join(" ")}
              onClick={() => handleClickLink(4)}
            >
              <span>Services</span>
              <span>Services</span>
              <span>Services</span>
            </button>
            <button
              className={[
                styles.button,
                activeIndex === 5 && styles.active,
              ].join(" ")}
              onClick={() => handleClickLink(5)}
            >
              <span>What We Do</span>
              <span>What We Do</span>
              <span>What We Do</span>
            </button>
            <button
              className={[
                styles.button,
                activeIndex === 6 && styles.active,
              ].join(" ")}
              onClick={() => handleClickLink(6)}
            >
              <span>Skills/Expertise</span>
              <span>Skills/Expertise</span>
              <span>Skills/Expertise</span>
            </button>
            <button
              className={[styles.button, activeIndex > 6 && styles.active].join(
                " "
              )}
              onClick={() => handleClickLink(7)}
            >
              <span>Portfolio</span>
              <span>Portfolio</span>
              <span>Portfolio</span>
            </button>
          </div>
        )}
        <span className={styles.text}>commenda.kr</span>
      </div>
    </section>
  );
};

export default Portfolio;
