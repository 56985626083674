import styles from "./sectionSeven.module.scss";

interface IProps {
  className?: string;
}

const SectionSeven = ({ className = "" }: IProps) => {
  return (
    <div className={[styles.section_seven, className].join(" ")}>
      <div className={styles.left}>
        <span className={styles.text}>Skills/Expertise</span>
      </div>
      <div className={styles.right}>
        <div className={styles.box}>
          <div className={styles.first}>
            <span className={styles.text}>Web Development</span>
            <span className={styles.text}>UI / UX Design</span>
            <span className={styles.text}>Web Design</span>
            <span className={styles.text}>iOS / Android OS</span>
            <span className={styles.text}>Infrastructure</span>
            <span className={styles.text}>Security Consultation</span>
          </div>
          <div className={styles.second}>
            <span className={styles.text}>Publishing</span>
            <span className={styles.text}>Branding & Identity</span>
            <span className={styles.text}>Data Analytics</span>
            <span className={styles.text}>SaaS</span>
            <span className={styles.text}>A.I.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSeven;
