import Icon from "@src/components/common/Icon";
import styles from "./sectionLast.module.scss";

interface IProps {
  className?: string;
}

const SectionLast = ({ className = "" }: IProps) => {
  return (
    <div className={[styles.section_last, className].join(" ")}>
      <Icon
        name="MainLogo"
        className={styles.main_logo}
      />
      <Icon
        name="Symbol"
        className={styles.symbol}
      />
      <span className={styles.text}>고맙습니다.</span>
    </div>
  );
};

export default SectionLast;
