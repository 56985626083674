import Icon from "@src/components/common/Icon";
import styles from "./sectionTwo.module.scss";

interface IProps {
  className?: string;
}

const SectionTwo = ({ className = "" }: IProps) => {
  return (
    <div className={[styles.section_two, className].join(" ")}>
      <div className={styles.box}>
        <div className={styles.left}>
          <div className={styles.fist}>
            <div className={styles.symbol}>
              <Icon name="Symbol" />
            </div>
          </div>
          <div className={styles.second}>
            <b>
              <span className={styles.text}>
                Omni <br />
                Channel.
              </span>
            </b>
          </div>
        </div>
        <div className={styles.right}>
          <b>
            <span className={styles.text}>
              기업이 핵심 비즈니스에 집중할 수 있도록.
            </span>
          </b>
          <span className={styles.text}>
            전문가들이 프로젝트의 모든 단계에서 <br />
            유연한 인력과 리소스를 관리하며 최적의 결과를 제공합니다.
          </span>
          <span className={styles.text}>
          IT 프로젝트의 모든 영역에서 국내외 시장의  <br />
          지속 가능한 성장을 지원하는 믿을 수 있는 파트너가 되겠습니다.
          </span>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
