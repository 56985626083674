import Icon from "@src/components/common/Icon";
import styles from "./sectionThree.module.scss";
import Pic from "@src/assets/images/pic.png";

interface IProps {
  className?: string;
}

const SectionThree = ({ className = "" }: IProps) => {
  return (
    <div className={[styles.section_three, className].join(" ")}>
      <div className={styles.left}>
        <Icon
          name="HeaderLogo"
          className={styles.logo}
        />
        <img
          className={styles.img}
          src={Pic}
          alt="img"
        />
      </div>
      <div className={styles.right}>
        <b>
          <span className={styles.text}>
            코멘다Commenda는 중세 이탈리아에서 <br />
            상인과 투자자 간의 신뢰를 바탕으로 위험을 공유하여 <br />
            성공적인 파트너십을 형성했던 제도입니다.
          </span>
        </b>
        <span className={styles.text}>
          이처럼 우리는 신뢰를 기반으로, <br />
          시장 변화에 빠르게 대응하고 위험을 분산시키는 <br />
          전략적 파트너로서 고객과 함께 성장해 나가고자 합니다.
        </span>
      </div>
    </div>
  );
};

export default SectionThree;
