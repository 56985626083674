import styles from "./sectionFive.module.scss";
import Icon from "@src/components/common/Icon";

interface IProps {
  className?: string;
}

const SectionFive = ({ className = "" }: IProps) => {
  return (
    <div className={[styles.section_five, className].join(" ")}>
      <div className={styles.left}>
        <div className={styles.box}>
          <Icon
            name="CycleRightShadow"
            className={styles.background}
          />
          <span className={styles.text}>
            Services <br />
            for <br />
            Start-up
          </span>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.box}>
          <span className={styles.title}>기획 디자인</span>
          <span className={styles.text}>
            아이디어를 솔루션으로 구체화하고, 솔루션을 제품으로 발전시키는 <br />
            전 과정에서 풍부한 경험을 바탕으로 제품 기획, 디자인, 개발까지 <br />
            함께합니다.
          </span>
        </div>
        <div className={styles.box}>
          <span className={styles.title}>제품 런치</span>
          <span className={styles.text}>
            POC 설계 및 제품 런치 단계에서는 개념 검증을 통해 아이디어의 <br />
            실현 가능성을 평가하고, 이후 제품 출시를 위한 최종 준비와 실행을 <br />
            체계적으로 진행합니다.
          </span>
        </div>
        <div className={styles.box}>
          <span className={styles.title}>팀빌딩</span>
          <span className={styles.text}>
            스타트업이 지속적으로 성장할 수 있도록 전문 인력을 추천하고 <br />
            팀빌딩을 지원합니다. 필요한 역할과 역량을 분석해 최적의 <br />
            팀구성을 돕고, 인재를 연결하여 장기적인 성장을 도모합니다.
          </span>
        </div>
      </div>
    </div>
  );
};

export default SectionFive;
